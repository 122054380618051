import { setup } from '@glow/language'
import i18n from 'i18next'
import dk from './dk'
import en from './en'
import fi from './fi'
import fr from './fr'
import nb from './nb'
import sv from './sv'

setup()
i18n.addResourceBundle('en', 'translation', en)
i18n.addResourceBundle('sv', 'translation', sv)
i18n.addResourceBundle('nb', 'translation', nb)
i18n.addResourceBundle('dk', 'translation', dk)
i18n.addResourceBundle('fi', 'translation', fi)
i18n.addResourceBundle('fr', 'translation', fr)

// You actually can't look up languages from i18n (wth?)
export const supportedLanguages = [
  // Language names are in the local language, so no matter the selected language you can identify "your" language
  // i.e. they should _not_ be translated
  { code: 'nb', name: 'Norsk' },
  { code: 'en', name: 'English' },
  { code: 'fi', name: 'Suomii' },
  { code: 'dk', name: 'Dansk' },
  { code: 'sv', name: 'Svenska' },
  { code: 'fr', name: 'Français' }
]
